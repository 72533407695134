import $ from 'jquery';

import ModuleHeader from '../modules/moduleHeader';
import ModuleContact from '../modules/moduleContact';
import ModuleStiftung from '../modules/moduleStiftung';
import ModuleProjects from '../modules/moduleProjects';
import CustomAnimation from '../animation/customAnimation';

class Homepage {
  init() {
    const moduleHeader = new ModuleHeader();
    moduleHeader.init();

    const moduleStiftung = new ModuleStiftung();
    moduleStiftung.init();

    const moduleProjects = new ModuleProjects();
    moduleProjects.init();

    const moduleContact = new ModuleContact();
    moduleContact.init();

    const customAnimation = new CustomAnimation();
    customAnimation.init();
  }
}

export default Homepage;
