import $ from 'jquery';
import {gsap} from "gsap";
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class ModuleStiftung {
  init() {
    this.initParallax();
  }

  initParallax = () => {
    customAnmation.animateHeadline('.stiftung_headline');
    customAnmation.animateTextLine('.stiftung_text_line');
    customAnmation.animateSubline('.stiftung_subline');
    customAnmation.animateCopy('.stiftung_copy');
  };
}

export default ModuleStiftung;
