import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class ModuleHeader {
  init() {
    this.initParallax();

    setTimeout(function () {
      //$('#impressumModal').show();
      //$('#impressumModal').addClass('show');
    }, 1000);
  }

  initParallax = () => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        gsap.to('.header_image', {
          scrollTrigger: {
            trigger: '#header',
            start: 'center 50%',
            end: 'bottom 0%',
            markers: false,
            scrub: 0
          },
          y: 0,
          scale: 1.1
        });
        gsap.to('.header_text_holder', {
          scrollTrigger: {
            trigger: '.header_text_holder',
            start: 'top 60%',
            end: 'bottom 0%',
            markers: false,
            scrub: 0
          },
          y: -80
        });
      },
      // small
      '(max-width: 767px)': function () {
        gsap.to('.header_image', {
          scrollTrigger: {
            trigger: '#header',
            start: 'center 50%',
            end: 'bottom 0%',
            markers: false,
            scrub: 0
          },
          y: -30,
          scale: 1.0
        });
        gsap.to('.header_text_holder', {
          scrollTrigger: {
            trigger: '.header_text_holder',
            start: 'top 60%',
            end: 'bottom 0%',
            markers: false,
            scrub: 0
          },
          y: -0
        });
      }
    });

    customAnmation.animateHeaderLogo();
  };
}

export default ModuleHeader;
