import $ from 'jquery';
import {gsap} from "gsap";
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class ModuleProjects {
  init() {
    this.initParallax();
  }

  initParallax = () => {
    gsap.from('.project_intro_content', {
      scrollTrigger: {
        trigger: '#projects',
        start: 'top 100%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      scale: 1.2
    });
    gsap.from('.projects_intro_holder', {
      scrollTrigger: {
        trigger: '.projects_intro_holder',
        start: 'top 100%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: 100
    });

    /* kunst */
    customAnmation.animateHeadline('.kunst_headline');
    customAnmation.animateTextLine('.kunst_text_line');
    customAnmation.animateCopy('.kunst_copy');

    /* bildung */
    customAnmation.animateHeadline('.bildung_headline');
    customAnmation.animateTextLine('.bildung_text_line');
    customAnmation.animateCopy('.bildung_copy');

    /* opferschutz */
    customAnmation.animateHeadline('.opferschutz_headline');
    customAnmation.animateTextLine('.opferschutz_text_line');
    customAnmation.animateCopy('.opferschutz_copy');

    customAnmation.animateGroup($('.project_image'));
  };
}

export default ModuleProjects;
