import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class CustomAnimation {
  init() {
    gsap.registerPlugin(ScrollTrigger);
  }

  animateHeaderLogo() {
    gsap.to('.logo .black', {
      scrollTrigger: {
        trigger: '#header',
        start: 'top 0%',
        end: '5% 0%',
        markers: false,
        scrub: 0
      },
      scale: 1,
      autoAlpha: 0
    });
  }

  animateTextLine(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'center 110%',
        end: 'center 70%',
        markers: false,
        scrub: 0
      },
      width: 0
    });
  }

  animateHeadline(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 100%',
        end: 'center 80%',
        markers: false,
        scrub: 0
      },
      y: 50,
      autoAlpha: 0
    });
  }

  animateFormElement(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 100%',
        end: 'center 80%',
        markers: false,
        scrub: 0
      },
      scale: 0.95
    });
  }

  animateSubline(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 100%',
        end: 'center 80%',
        markers: false,
        scrub: 0
      },
      y: 50,
      autoAlpha: 0
    });
  }

  animateCopy(element) {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 110%',
        end: 'center 70%',
        markers: false,
        scrub: 0
      },
      y: 50,
      autoAlpha: 0
    });
  }

  animateGroup(elements) {
    const elementGroup = gsap.utils.toArray(elements);
    elementGroup.forEach((element) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 120%',
          end: 'top 65%',
          markers: false,
          scrub: true
        },
        y: 75,
        scale: '.8'
      });
    });
  }
}

export default CustomAnimation;
