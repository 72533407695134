import $ from 'jquery';
import { gsap } from 'gsap';
import validate from 'jquery-validation';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class ModuleContact {
  init() {
    // this.initForm();
    this.initParallax();
    this.initTheForm();
  }

  initTheForm() {
    this.resetForm();
    const formMessages = $('#form-messages');

    //formMessages.text('test');
    $("form[name='registration']").validate({
      rules: {
        name: 'required',
        phone: 'required',
        email: {
          required: true,
          email: true
        },
        nachricht: 'required',
        policy: 'required'
      },
      messages: {
        name: 'Bitte geben Sie Ihren Namen ein.',
        firma: 'Bitte geben Sie Ihren Firma ein.',
        phone: 'Bitte geben Sie Ihre Telefon-Nummer ein.',
        nachricht: 'Bitte geben Sie Ihre Nachricht ein.',
        policy: 'Bitte akzeptieren Sie die Bedingungen.',
        email: 'Bitte geben Sie Ihre korrekte E-Mail ein.'
      },
      submitHandler(form) {
        //form.submit();

        const formData = $(form).serialize();

        $.ajax({
          type: 'POST',
          url: 'php/contact_form.php',
          data: formData
        })
          .done(function (response) {
            //alert('OK');

            $('#name').val('');
            $('#firma').val('');
            $('#phone').val('');
            $('#email').val('');
            $('#nachricht').val('');
            $('#policy').prop('checked', false);

            // Set the message text.
            formMessages.text(response);
          })
          .fail(function (data) {
            //alert('FAIL');

            // Set the message text.
            if (data.responseText !== '') {
              console.log(data.responseText);
              formMessages.text(data.responseText);
            } else {
              console.log('ERROR');
              formMessages.text('Oops! An error occured and your message could not be sent.');
            }
            // alert('Send FAIL');
          });
      }
    });
  }

  initForm() {
    this.resetForm();

    const formMessages = $('#form-messages');
    const forms = document.getElementsByClassName('needs-validation');

    const validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener(
        'submit',
        function (event) {
          console.log(`form.checkValidity() = ${form.checkValidity()}`);
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          } else {
            event.preventDefault();

            // alert("OK");

            const formData = $(form).serialize();

            $.ajax({
              type: 'POST',
              url: 'contact_form.php',
              data: formData
            })
              .done(function (response) {
                // Make sure that the formMessageBottom div has the 'success' class.
                formMessages.removeClass('success');
                formMessages.addClass('error');

                form.classList.remove('was-validated');

                // $("#form")[0].reset();

                this.resetForm();

                // Set the message text.
                formMessages.text(response);

                // alert('Send DONE');
              })
              .fail(function (data) {
                // Make sure that the formMessageBottom div has the 'error' class.
                formMessages.removeClass('success');
                formMessages.addClass('error');

                // Set the message text.
                if (data.responseText !== '') {
                  formMessages.text(data.responseText);
                } else {
                  formMessages.text('Oops! An error occured and your message could not be sent.');
                }
                // alert('Send FAIL');
              });
          }

          form.classList.add('was-validated');
        },
        false
      );

      // reset validation
      form.addEventListener(
        'reset',
        function (event) {
          form.classList.remove('was-validated');
          formMessages.text('');
        },
        false
      );
    });
  }

  initParallax = () => {
    customAnmation.animateHeadline('.form_headline');
    customAnmation.animateFormElement('.form-group_01');
    customAnmation.animateFormElement('.form-group_02');
    customAnmation.animateFormElement('.form-group_03');
    customAnmation.animateFormElement('.form-group_04');
    customAnmation.animateFormElement('.form-group_05');
    customAnmation.animateFormElement('.form-group_06');
    customAnmation.animateFormElement('.form-group_07');

    customAnmation.animateHeadline('.contact_headline');
    customAnmation.animateHeadline('.contact_button_holder');
  };

  resetForm() {
    $('#name').val('');
    $('#firma').val('');
    $('#firma2').val('');
    $('#email').val('');
    $('#phone').val('');
    $('#nachricht').val('');
    $('#policy').prop('checked', false);
  }
}

export default ModuleContact;
